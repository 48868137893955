import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  Avatar,
  Box,
  IconButton,
  TextareaAutosize
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionIcon from '@material-ui/icons/Description';
import compose from 'recompose/compose';
import moment from 'moment';
import { updateOfficeProjectHandover, handOverSubmit } from '../../../api'
import GetAppIcon from '@material-ui/icons/GetApp';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Swal from 'sweetalert2';

const styles = theme => ({
  closeIcon: {
    float: 'right',
    cursor: 'pointer',
  },
  imageFileContainer: {
    position: 'relative',
    marginTop: '1rem',
    width: '100px',
    // height: '70px',
  },
  imageFile: {
    width: '100%',
    height: '100%',
  },
  flex: {
    flex: 1,
  },
  removeImage: {
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    color: 'white',
    background: '#dc3545',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    zIndex: '1',
  },
  remarksContainer: {
    marginTop: '1rem',
  },
  remark_text_area: {
    width: '100%',
  }
});

class ProjectHandover extends Component {

  state = {
    office_project_handover_details: this.props.project.office_project_handover_details,
    modalOpen: false,
    imageUrl: null,
    files: [],
    fileData: null,
    remark: null,
  }

  approveNow = () => {
    const { project } = this.props;
    const data = {
      id: project.office_project_handover_details.id,
      is_approved: true
    }
    updateOfficeProjectHandover(this.props.token, data)
    .then(res => {
      if (res.status === 200) {
        this.setState({ office_project_handover_details: res.data })
      }
    })
    .catch(error => console.log(error))
  }

  handleOpenModal = () => {
    this.setState({ modalOpen: true })
    this.setState({ isFileTouched: false })
    this.setState({ isRemarkTouched: false })
  };

  handleClose = () => {
    this.setState({ modalOpen: false, files: [], fileData: null, remark: null })
  };

  removeFile = (file) => {
    let res = this.state.files.filter((item) => {
      if (item.name == file) return false;
      else return true;
    });
    this.setState({ files: res });
  }

  handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    console.log("Selected Files:", selectedFiles);
  
    // Validate file types
    const validFiles = selectedFiles.filter((file) =>
      ["image/jpeg", "image/png", "application/pdf"].includes(file.type)
    );
  
    if (validFiles.length !== selectedFiles.length) {
      this.setState({ error: "Only JPEG, PNG, and PDF files are allowed." });
    } else {
      this.setState({ error: "" });
    }
  
    // Update state with valid files
    this.setState(
      (prevState) => ({
        files: [...prevState.files, ...validFiles],
      }),
      () => console.log("Updated Files State:", this.state.files)
    );
  
    // Prepare FormData
    const formData = new FormData();
    validFiles.forEach((file, index) => {
      formData.append(`files[]`, file);
    });
  
    // Verify FormData contents
    console.log("FormData Contents:");
    for (let [key, value] of formData.entries()) {
      console.log(key, value.name); // Display file name
    }
  
    // Update state with FormData
    this.setState({ fileData: formData }, () =>
      console.log("Updated FormData State:", this.state.fileData)
    );
  };
  


  submitForm = () => {
    const remark = this.state.remark || '';
    const project_id = this.props.project_id || '';
    handOverSubmit(this.props.token, this.state.fileData, remark, project_id)
      .then(res => {
        if (res.status == 200) {
          this.handleClose();
          Swal.fire("Success", "Handover Submitted", "success")
          .then((res) => {
            if (res.isConfirmed) {
              window.location.reload();
            }
          });
        } else if (res.status == 202) {
          this.handleClose();
          Swal.fire("Warning", res.data.message, "warning");
        } else {
          this.handleClose();
          Swal.fire("Error", res.data.message, "error");
        }
      }).catch(err => {
        this.handleClose();
        Swal.fire("Error", err.data.message, "error");
      });
  }

  render() {
    const { classes } = this.props;
    const { office_project_handover_details } = this.state;
    return office_project_handover_details &&
      office_project_handover_details?.length !== 0 ? (
      <div>
        <Card>
          <CardHeader
            // action={
            //   <>
            //     { !office_project_handover_details.is_approved &&
            //       <Button variant="contained" color="primary" onClick={this.approveNow}>
            //         Approve Now
            //       </Button>
            //     }
            //   </>
            // }
            title="Project Handover Details"
          />
          <CardContent>
            <Grid container spacing={0}>
              <Grid item xs={12} sm={4}>
                <strong> Approval Status: </strong>
              </Grid>
              <Grid item xs={12} sm={8}>
                {office_project_handover_details.is_approved ? "Yes" : "No"}
              </Grid>

              {office_project_handover_details.is_approved && (
                <>
                  <Grid item xs={12} sm={4}>
                    <strong> Approved By(Project Manager): </strong>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    {office_project_handover_details.approved_by_details.name}{" "}
                    <br />
                    {
                      office_project_handover_details.approved_by_details.email
                    }{" "}
                    <br />
                    {
                      office_project_handover_details.approved_by_details
                        .contact
                    }{" "}
                    <br />
                  </Grid>
                </>
              )}
              {office_project_handover_details.raised_by_details !==
                undefined &&
                office_project_handover_details.raised_by_details.length !==
                  0 && (
                  <>
                    <Grid item xs={12} sm={4}>
                      <strong> Raised By(Site Supervisor): </strong>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      {office_project_handover_details.raised_by_details.name}
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <strong> Raised By(Site Supervisor) Details: </strong>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      {office_project_handover_details.raised_by_details.email}
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <strong></strong>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      {
                        office_project_handover_details.raised_by_details
                          .contact
                      }
                    </Grid>
                  </>
                )}

              <Grid item xs={12} sm={4}>
                <strong> Raised on: </strong>
              </Grid>
              <Grid item xs={12} sm={8}>
                {moment(office_project_handover_details.created_at).format(
                  "lll"
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <strong> Remark: </strong>
              </Grid>
              <Grid item xs={12} sm={8}>
                {office_project_handover_details.remark}
              </Grid>

              <Grid container xs={12} wrap spacing={3}>
                {office_project_handover_details.files?.length !== 0 &&
                  office_project_handover_details.files.map((file, index) => {
                    return (
                      <React.Fragment>
                        <Grid item>
                          {file.file_content_type == "application/pdf" ? (
                            <a href={file.file} target="_blank" alt="download">
                              <Avatar
                                variant="square"
                                className={classes.imageFile}
                                style={{
                                  height: "100px",
                                  width: "100px",
                                  objectFit: "cover",
                                }}
                              >
                                <DescriptionIcon />
                              </Avatar>
                            </a>
                          ) : (
                            <a href={file.file} target="_blank" alt="download">
                              <img
                                src={file.file}
                                alt={file.file_file_name}
                                height="200"
                                weight="200"
                                style={{
                                  height: "100px",
                                  width: "100px",
                                  objectFit: "cover",
                                }}
                              />
                            </a>
                          )}
                        </Grid>
                      </React.Fragment>
                    );
                  })}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    ) : (
      <React.Fragment>
        <Card>
          <CardHeader title="Project Handover" />
          <CardContent>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={this.handleOpenModal}
              >
                Complete Handover
              </Button>
            </Grid>
          </CardContent>
        </Card>

        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.modalOpen}
          maxWidth="sm"
          fullWidth>
          <ValidatorForm
            onSubmit={() => this.submitForm()}
            onError={(errors) => console.log(errors)} >
            <DialogTitle
              id="customized-dialog-title"
              onClose={this.handleClose}>
              Project Handover
              {/* <IconButton
                aria-label="close"
                className={classes.closeIcon}
                onClick={this.handleClose}
                size="large">
                <CloseIcon />
              </IconButton> */}
            </DialogTitle>
            <DialogContent dividers>
              <Box display="flex" style={{ gap: "15px" }}>
                {/* File Upload Section */}
                <Box display="flex">
                  <Typography variant="subtitle1" gutterBottom>
                    Handover Files : *
                  </Typography>
                </Box>
                <Box flex={1} className={classes.image_container}>
                  <input
                    type="file"
                    accept="image/jpeg,image/png,application/pdf"
                    id="file-input"
                    className="file-input"
                    style={{ display: "none" }}
                    onChange={this.handleFileChange}
                    multiple
                    onBlur={() => this.setState({ isFileTouched: true })}/>

                  <label htmlFor="file-input">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span">
                      Select Files
                    </Button>
                  </label>
                  <Box>
                    {this.state.isFileTouched &&
                      this.state.files?.length === 0 && (
                        <Typography variant="caption" color="error">
                          Please upload at least one file.
                        </Typography>
                      )}
                  </Box>
                  <Grid container spacing={2}>
                    {this.state.files.length > 0 ? (
                      this.state.files.map((file) => (
                        <Grid
                          key={file.name}
                          item
                          style={{
                            width: "120px",
                          }}>
                          <Box
                            className={classes.imageFileContainer}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            border={1}
                            borderColor="grey.300"
                            borderRadius={5}
                            position="relative">
                            <IconButton
                              aria-label="remove file"
                              className={classes.removeImage}
                              onClick={() => this.removeFile(file.name)}
                              size="small"
                              style={{
                                position: "absolute",
                                top: -6,
                                right: -10,
                                backgroundColor: "#f1f1f1",
                                color:"black",
                              }} >
                              <CloseIcon fontSize="small" style={{padding:"2px"}}/>
                            </IconButton>
                            {file.type === "image/png" ||
                            file.type === "image/jpeg" ? (
                              <img
                                alt="Uploaded"
                                src={URL.createObjectURL(file)}
                                className={classes.imageFile}
                                style={{
                                  maxWidth: "100px",
                                  maxHeight: "130px",

                                }}/>
                            ) : (
                              <Avatar
                                variant="square"
                                style={{
                                  backgroundColor: "#e0e0e0",
                                  width: "100px",
                                  height: "100px",
                                }}>
                                <DescriptionIcon />
                              </Avatar>
                            )}
                            <Typography variant="caption" Wrap style={{padding:"3px"}}>
                              {file.name}
                            </Typography>
                          </Box>
                        </Grid>
                      ))
                    ) : (
                      // <Grid item>
                      //   <Typography>No files uploaded yet.</Typography>
                      // </Grid>
                      <></>
                    )}
                  </Grid>
                </Box>
              </Box>

              {/* Remarks Section */}
              <Box
                marginTop={3}
                className={classes.remarksContainer}
                display="flex"
                style={{ gap: "15px" }}
              >
                <Typography variant="subtitle1">Remarks *:</Typography>
                <Box flex={1} style={{ marginLeft: "48px" }}>
                  <TextareaAutosize
                    aria-label="remarks"
                    value={this.state.remark}
                    rowsMin={3}
                    placeholder="Enter remarks here."
                    className={classes.remark_text_area}
                    onChange={(e) =>
                      this.setState({
                        remark: e.target.value,
                        isRemarkTouched: true,
                      })
                    }
                    onBlur={() => this.setState({ isRemarkTouched: true })}
                    style={{
                      width: "100%",
                      padding: "10px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    }}
                  />
                  {this.state.isRemarkTouched && !this.state.remark?.trim() && (
                    <Typography variant="caption" color="error">
                      Remarks cannot be empty.
                    </Typography>
                  )}
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    align="right"
                    display="block"
                    style={{ marginTop: "4px" }}
                  >
                    {this.state.remark?.length} / 200 characters
                  </Typography>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="secondary">
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!this.state.files?.length || !this.state.remark?.trim()}>
                Submit Handover
              </Button>
            </DialogActions>
          </ValidatorForm>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
};

export default compose(withStyles(styles),connect(mapStateToProps))(ProjectHandover);