import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Card,
  Box,
  CardContent,
  CardHeader,
  CardActions,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Avatar,
  TextareaAutosize,
  Tooltip
} from "@material-ui/core";
// import Box from '@mui/material/Box';
// import IconButton from '@mui/material/IconButton';

import { CircularProgress } from '@material-ui/core';
import moment from 'moment';

import { withStyles } from "@material-ui/core/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import CloseIcon from "@material-ui/icons/Close";
import DescriptionIcon from "@material-ui/icons/Description";
import Swal from "sweetalert2";
import { getLeadDetails, handOverSubmit,confirmhandover } from "../../api/PE/ProjectHandover";
import { Height } from "@material-ui/icons";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AdjustIcon from '@material-ui/icons/Adjust';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import { camelCase, upperCase } from "lodash";

const styles = {
  container: {
    padding: "20px",
    fontFamily: "Arial, sans-serif",
  },
  searchSection: {
    marginBottom: "20px",
  },
  input: {
    width: "300px",
    padding: "5px",
    marginRight: "10px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  searchButton: {
    backgroundColor: "#3f51b5",
    color: "white",
    padding: "5px 15px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  closeIcon: {
    cursor: "pointer",
    position: "absolute",
    right: "10px",
    top: "10px",
  },
  tableHeader: {
    border: "1px solid #ccc",
    padding: "5px",
    textAlign: "center",
    backgroundColor: "#f5f5f5",
  },
  tableCell: {
    border: "1px solid #ccc",
    padding: "5px",
    textAlign: "center",
  },
  remarkTextarea: {
    width: "100%",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    marginTop: "20px",
  },
  imagedisplayed:{
    width:"50px",
    Height:"70px"
  },
  datafeils:{
    fontWeight: '600',    
  }
};

class ProjectHandover extends Component {
  state = {
    leadid: "",
    leaddetails: "",
    modalOpen: false,
    files: [],
    remark: "",
    loading: false, // Add this line
  };
  


  handleSearch = () => {
    this.setState({ loading: true }); // Set loading to true before the API call

    const { token } = this.props;
    const { leadid } = this.state;

    getLeadDetails(token, leadid)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ leaddetails: res.data });
        } else {
          this.setState({ leaddetails: '' });
          Swal.fire("Error", "Failed to fetch lead details.", "error");
        }
      })
      .catch((err) => {
        this.setState({ leaddetails: '' });
        Swal.fire("Error", err.response.data.message, "error");
      })
      .finally(() => {
        this.setState({ loading: false }); // Set loading to false after the API call
      });
  ;
  };

  handleInputChange = (event) => {
    this.setState({ leadid: event.target.value });
  };

  handleOpenModal = () => {
    this.setState({ loading: true });

    const { token } = this.props;
    const { leadid } = this.state;
    confirmhandover(token, leadid)
    .then((res) => {
      if (res.status === 200) {
        this.setState({ modalOpen: true })
        this.setState({ isFileTouched: false })
        this.setState({ isRemarkTouched: false })
      } else if (res.status === 202) { 
        Swal.fire("Error", res.data.message, "error");
      }
    })
    .catch((err) => {
      Swal.fire("Error", err.response.data.message, "error");
    })
    .finally(() => {
      this.setState({ loading: false }); // Set loading to false after the API call
    });


  };

  handleClose = () => {
    this.setState({ modalOpen: false, files: [], fileData: '', remark: '' })
  };

  removeFile = (file) => {
    let res = this.state.files.filter((item) => {
      if (item.name == file) return false;
      else return true;
    });
    this.setState({ files: res });
  }

  //  params: {
  //   remark: remark,
  //   project_id: project_id,
  // }

  handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    console.log("Selected Files:", selectedFiles);
  
    // Validate file types
    const validFiles = selectedFiles.filter((file) =>
      ["image/jpeg", "image/png", "application/pdf"].includes(file.type)
    );
  
    if (validFiles.length !== selectedFiles.length) {
      this.setState({ error: "Only JPEG, PNG, and PDF files are allowed." });
    } else {
      this.setState({ error: "" });
    }
  
    // Update state with valid files
    this.setState(
      (prevState) => ({
        files: [...prevState.files, ...validFiles],
      }),
      () => console.log("Updated Files State:", this.state.files)
    );
  
    // Prepare FormData
    const formData = new FormData();
    validFiles.forEach((file, index) => {
      formData.append(`files[]`, file);
    });
  
    // Verify FormData contents
    console.log("FormData Contents:");
    for (let [key, value] of formData.entries()) {
      console.log(key, value.name); // Display file name
    }
  
    // Update state with FormData
    this.setState({ fileData: formData }, () =>
      console.log("Updated FormData State:", this.state.fileData)
    );
  };

  submitForm = () => {
    const remark = this.state.remark || '';
    const project_id = this.state.leadid || '';
    console.log(project_id)
    // this.formData.append('remark', this.state.remark)
    // this.formData.append('project_id', this.state.leadid)

    handOverSubmit(this.props.token, this.state.fileData, remark, project_id)
      .then(res => {
        if (res.status == 200) {
          this.handleClose();
          Swal.fire("Success", "Handover Submitted", "success")
          .then((res) => {
            if (res.isConfirmed) {
              window.location.reload();
            }
          });
        } else if (res.status == 202) {
          this.handleClose();
          console.log("trdctwtedcwtd", res)
          Swal.fire("Warning", res.data.message, "warning");
        } else {
          this.handleClose();
          Swal.fire("Error", res.data.message, "error");
        }
      }).catch(err => {
        this.handleClose();
        Swal.fire("Error", err.data.message, "error");
      });
  }




  render() {
    const { classes } = this.props;
    const { modalOpen, remark, files } = this.state;

    return (
      <div className={classes.container}>
        <Card>
        <CardContent>
        <h2 style={{marginBottom:"15px"}} >Project Handover</h2>
        <div className={classes.searchSection}>
          <input
            type="text"
            placeholder="Enter Lead ID"
            className={classes.input}
            onChange={this.handleInputChange}
          />
          <button className={classes.searchButton} onClick={this.handleSearch}>
            Search
          </button>
        </div>

        {this.state.loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <CircularProgress />
        </div>
      ) : (

        <div class="" style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
          {this.state.leaddetails? (
            <>
            

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                {/* Lead Details Section */}
                <div style={{ width: "30%" }}>
                <CardHeader  title="Lead Details"/>
                  {/* <h3>Lead Details</h3> */}
                  <CardContent>
                  <p>
                    Lead ID:&nbsp;
                    <span style={styles.datafeils}>{this.state.leaddetails?.lead_details?.lead_id}</span>
                  </p>
                  <p>
                    Lead Name:&nbsp;
                    <span style={styles.datafeils}>
                      {this.state.leaddetails?.lead_details?.lead_name}
                    </span>
                  </p>
                  <p>
                    P2P Date:&nbsp;
                    <span style={styles.datafeils}>
                      {this.state.leaddetails?.lead_details?.p2p_date}
                    </span>
                  </p>
                  <p>
                    Expected Handover Date:&nbsp;
                    <span style={styles.datafeils}>
                      {
                        this.state.leaddetails?.lead_details
                          ?.expected_handover_date
                      }
                    </span>
                  </p>
                  <p>
                    Collection Status:&nbsp;
                    <span style={styles.datafeils}>
                      {this.state.leaddetails?.lead_details?.collection_status}
                    </span>
                  </p>
                  <p>
                    Designer:&nbsp;
                    <span style={styles.datafeils}>
                      {this.state.leaddetails?.lead_details?.designer_name}
                    </span>
                  </p>
                  <p>
                    Community Manager:&nbsp;
                    <span style={styles.datafeils}>{this.state.leaddetails?.lead_details?.cm_name}</span>
                  </p>
                  </CardContent>
                </div>

                {/* Collection Details Section */}
                <div style={{ width: "35%" }}>
                <CardHeader  title="Collection Details"/>
                <CardContent>
                  <p>
                    Total Lead Value:&nbsp;
                    <span style={styles.datafeils}> 
                      {(this.state.leaddetails?.collection_details?.total_lead_value) ? 
                        <>
                          <i class="fa fa-inr"></i> {this.state.leaddetails?.collection_details?.total_lead_value}
                        </> : '0'
                      }
                    </span>
                  </p>
                  <p>
                    Total Collection Value:&nbsp;
                    <span style={styles.datafeils}>
                      {(this.state.leaddetails?.collection_details?.total_collection_value) ? 
                        <> 
                          <i class="fa fa-inr"></i> {this.state.leaddetails?.collection_details?.total_collection_value}
                        </> : '0'
                      }
                    </span>
                  </p>
                  <p>
                    Pending Balance:&nbsp;
                    <span style={styles.datafeils}>  
                      {
                        (this.state.leaddetails?.collection_details?.pending_balance) ? 
                        <> 
                          <i class="fa fa-inr"></i> {this.state.leaddetails?.collection_details?.pending_balance}
                        </> : '0'
                      }
                    </span>
                  </p>

                  {this.state.leaddetails?.collection_details?.boq_details.length>0 ? (
                    <table
                    style={{
                      width: "100%",
                      borderCollapse: "collapse",
                      marginTop: "10px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th style={styles.tableHeader}>BOQ Number</th>
                        <th style={styles.tableHeader}>BOQ Value</th>
                        <th style={styles.tableHeader}>Amount Mapped</th>
                        <th style={styles.tableHeader}>Status</th>
                      </tr>
                    </thead>
                    <tbody>
      {this.state.leaddetails?.collection_details?.boq_details?.map((boq, index) => (
        <tr key={index}>
          <td style={styles.tableCell}>{boq.boq_number}</td>
          <td style={styles.tableCell}>
          {
            (boq.boq_value) ? 
              <> 
                <i class="fa fa-inr"></i> {boq.boq_value.toFixed(0)}
              </> : '0'
          }
          </td>
          <td style={styles.tableCell}>
            {
              (boq.paid_amount) ? 
                <> 
                  <i class="fa fa-inr"></i> {boq.paid_amount.toFixed(0)}
                </> : '0'
            }
            {/* ₹ {boq.paid_amount !== null ? boq.paid_amount.toFixed(0) : "N/A"} */}
          </td>
          <td style={{...styles.tableCell}}>
            {boq.status === 'Pending' ? (
              <Tooltip title="Pending">
                <AssignmentLateIcon style={{ color: "red" }}/>
              </Tooltip>
            ) : (
              <Tooltip title="Paid">
                <CheckCircleOutlineIcon style={{ color: "green" }} />
              </Tooltip>

            )}
          </td>
        </tr>
      ))}
    </tbody>
                  </table>
                  ) : (<></>) }
                  </CardContent>
                </div>

                {/* Snag Details Section */}
                <div style={{ width: "30%" }}>
                <CardHeader  title="Snag Details"/>
                <CardContent>
                  <p>
                    Total Snag Requests:&nbsp;
                    <span style={styles.datafeils}>
                      {this.state.leaddetails?.snag_details?.total_snag_request}
                    </span>
                  </p>
                  <p>
                    Closed Snags:&nbsp;
                    <span style={styles.datafeils}>
                      {
                        this.state.leaddetails?.snag_details
                          ?.total_snag_completed
                      }
                    </span>
                  </p>
                  <p>
                    Pending Snags:&nbsp;
                    <span style={styles.datafeils}>
                      {this.state.leaddetails?.snag_details?.total_snag_pending}
                    </span>
                  </p>
                  {this.state.leaddetails?.snag_details?.snag_details.length>0 ? (

                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "collapse",
                      marginTop: "10px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th style={styles.tableHeader}>Snag Number</th>
                        <th style={styles.tableHeader}>Snag Date</th>
                        <th style={styles.tableHeader}>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.leaddetails?.snag_details?.snag_details?.map((snag,index)=>(
                        <tr key={index}>
                        <td style={styles.tableCell}>{snag.snag_number}</td>
                        <td style={styles.tableCell}>{snag.snag_date}</td>
                        <td style={{...styles.tableCell,textTransform: "capitalize"}}>{snag.snag_status}</td>
                      </tr>
                      ))}
                      
                    </tbody>
                  </table>
                  ) : (<></>)}
                  </CardContent>
                </div>
              </div>
              {this.state.leaddetails?.office_project_handover_details?.id || this.state.leaddetails?.office_project_handover_details?.id== '0' ? (
                <div>
         <CardHeader
            title="Project Handover Details"
          />
          <CardContent>
          <Grid container spacing={0}>
              
            <Grid item xs={12} sm={4}><strong> Approval Status: </strong></Grid>
            <Grid item xs={12} sm={8}>{this.state.leaddetails.office_project_handover_details.is_approved ? "Yes" : "No"}</Grid>

              {this.state.leaddetails.office_project_handover_details.is_approved &&
                <>
                  <Grid item xs={12} sm={4}><strong> Approved By(Project Manager): </strong></Grid>
                  <Grid item xs={12} sm={8}>
                    {this.state.leaddetails.office_project_handover_details.approved_by_details.name} <br />
                    {this.state.leaddetails.office_project_handover_details.approved_by_details.email} <br />
                    {this.state.leaddetails.office_project_handover_details.approved_by_details.contact} <br />
                  </Grid>
                </>
              }
            { this.state.leaddetails.office_project_handover_details.raised_by_details !== undefined && 
              this.state.leaddetails.office_project_handover_details.raised_by_details.length !== 0 &&
              <> 
                <Grid item xs={12} sm={4}><strong> Raised By(Site Supervisor): </strong></Grid>
                <Grid item xs={12} sm={8}>{this.state.leaddetails.office_project_handover_details.raised_by_details.name}</Grid>

                <Grid item xs={12} sm={4}><strong> Raised By(Site Supervisor) Details: </strong></Grid>
                <Grid item xs={12} sm={8}>{this.state.leaddetails.office_project_handover_details.raised_by_details.email}</Grid>
                
                <Grid item xs={12} sm={4}><strong></strong></Grid>
                <Grid item xs={12} sm={8}>{this.state.leaddetails.office_project_handover_details.raised_by_details.contact}</Grid>
              </>
            }
            
            <Grid item xs={12} sm={4}><strong> Raised on: </strong></Grid>
            <Grid item xs={12} sm={8}>{moment(this.state.leaddetails.office_project_handover_details.created_at).format('lll')}</Grid>
            <Grid item xs={12} sm={4}><strong> Remark: </strong></Grid>
            <Grid item xs={12} sm={8}>{this.state.leaddetails.office_project_handover_details.remark}</Grid>
              
            <Grid container xs={12} wrap spacing={3}>
            {
              this.state.leaddetails.office_project_handover_details.files.length !== 0 &&
              this.state.leaddetails.office_project_handover_details.files.map((file, index) => {
                return (
                  <React.Fragment>
                  <Grid item>
                    {file.file_content_type == "application/pdf" ? (
                      <a href={file.file} target="_blank" alt="download">
                        <Avatar
                          variant="square"
                          className={classes.imageFile}
                          style={{ height: '100px', width: '100px', objectFit: 'cover'}}
                        >
                          <DescriptionIcon />
                        </Avatar>
                      </a>
                    ) : (
                      <a href={file.file} target="_blank" alt="download">
                      <img
                        src={file.file}
                        alt={file.file_file_name}
                        height="200"
                        weight="200"
                        style={{ height: '100px', width: '100px', objectFit: 'cover'}}
                      />
                      </a>
                    )}
                  </Grid>
                  </React.Fragment>
                );
              })
            }
            </Grid>
          </Grid>
        </CardContent>
                </div>
              )
                : (
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleOpenModal}>
                  Handover
                </Button>
              </div>)}
              
            </>
            

          ):<></>}
        </div>

      )}


<Dialog
  onClose={this.handleClose}
  aria-labelledby="customized-dialog-title"
  open={this.state.modalOpen}
  maxWidth="sm"
  fullWidth
>
  <ValidatorForm
    onSubmit={() => this.submitForm()}
    onError={(errors) => console.log(errors)}
  >
    <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
      Project Handover
      <IconButton
        aria-label="close"
        className={classes.closeIcon}
        onClick={this.handleClose}
        size="large"
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent dividers>
      <Box display="flex" style={{ gap: '15px' }}>
        {/* File Upload Section */}
        <Box display="flex">
          <Typography variant="subtitle1" gutterBottom>
            Upload New Files *
          </Typography>
        </Box>
        <Box flex={1} className={classes.image_container}>
          <input
            type="file"
            accept="image/jpeg,image/png,application/pdf"
            id="file-input"
            className="file-input"
            style={{ display: "none" }}
            onChange={this.handleFileChange}
            multiple
            onBlur={() => this.setState({ isFileTouched: true })}
          />
          
          <label htmlFor="file-input">
            <Button variant="contained" color="primary" component="span">
              Select Files
            </Button>
          </label>
          <Box>
          {this.state.isFileTouched && this.state.files.length === 0 && (
            <Typography variant="caption" color="error">
              Please upload at least one file.
            </Typography>
          )}
          </Box>
          <Grid container spacing={2}>
            {this.state.files.length > 0 ? (
              this.state.files.map((file) => (
                <Grid key={file.name} item
                style={{
                  width: "120px",
                }}>
                  <Box
                    className={classes.imageFileContainer}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    border={1}
                    borderColor="grey.300"
                    borderRadius={5}
                    position="relative"
                  >
                    <IconButton
                      aria-label="remove file"
                      className={classes.removeImage}
                      onClick={() => this.removeFile(file.name)}
                      size="small"
                      style={{
                        position: "absolute",
                        top: -6,
                        right: -10,
                        backgroundColor: "#f1f1f1",
                        color:"black"
                      }}
                    >
                      <CloseIcon fontSize="small" style={{padding:"2px"}}/>
                    </IconButton>
                    {file.type === "image/png" || file.type === "image/jpeg" ? (
                      <img
                        alt="Uploaded"
                        src={URL.createObjectURL(file)}
                        className={classes.imageFile}
                        style={{
                          maxWidth: "100px",
                          maxHeight: "130px",
                        }}
                      />
                    ) : (
                      <Avatar
                        variant="square"
                        style={{
                          backgroundColor: "#e0e0e0",
                          width: "100px",
                          height: "100px",
                        }}
                      >
                        <DescriptionIcon />
                      </Avatar>
                    )}
                    <Typography variant="caption" Wrap padding={2}>
                      {file.name}
                    </Typography>
                  </Box>
                </Grid>
              ))
            ) : (
              // <Grid item>
              //   <Typography>No files uploaded yet.</Typography>
              // </Grid>
              <></>
            )}
          </Grid>
        </Box>
      </Box>

      {/* Remarks Section */}
      <Box marginTop={3} className={classes.remarksContainer} display="flex" style={{ gap: '15px' }}>
        <Typography variant="subtitle1">Remarks *:</Typography>
        <Box flex={1} style={{ marginLeft:"55px"}}>
          <TextareaAutosize
            aria-label="remarks"
            value={this.state.remark}
            rowsMin={3}
            placeholder="Enter remarks here."
            className={classes.remark_text_area}
            onChange={(e) =>
              this.setState({
                remark: e.target.value,
                isRemarkTouched: true,
              })
            }
            onBlur={() => this.setState({ isRemarkTouched: true })}
            style={{
              width: "100%",
              padding: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
             
            }}
          />
          {this.state.isRemarkTouched && !this.state.remark.trim() && (
            <Typography variant="caption" color="error">
              Remarks cannot be empty.
            </Typography>
          )}
          <Typography
            variant="caption"
            color="textSecondary"
            align="right"
            display="block"
            style={{ marginTop: "4px" }}
          >
            {this.state.remark.length} / 200 characters
          </Typography>
        </Box>
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={this.handleClose} color="secondary">
        Cancel
      </Button>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={!this.state.files.length || !this.state.remark.trim()}
      >
        Submit
      </Button>
    </DialogActions>
  </ValidatorForm>
</Dialog>



        
        </CardContent>
        </Card>
      
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
});

export default connect(mapStateToProps)(withStyles(styles)(ProjectHandover));