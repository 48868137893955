import axios from "../base";

export function getLeadDetails(accessToken, lead_id) {
  return axios.request({
    url: "api/v1/office_projects/fetch_lead_details/?lead_id=" + lead_id,
    method: "GET",
    headers: accessToken,
  });
}


export function confirmhandover(accessToken, lead_id) {
  return axios.request({
    url: "api/v1/office_projects/handover_lead_validation/?lead_id=" + lead_id,
    method: "PUT",
    headers: accessToken,
  });
}


export function handOverSubmit(assessToken, data, remark, project_id) {
    return axios.request({
      url: `/api/v1/office_projects/handover_lead`,
      method: 'PUT',
      headers: assessToken,
      data: data,
      params: {
        remark: remark,
        lead_id: project_id,
      }
    })
  }

//   http://qa-officeapi.arrivae.com/api/v1/office_projects/handover_lead/

// http://qa-officeapi.arrivae.com/api/v1/office_projects/fetch_lead_details/?lead_id=234117